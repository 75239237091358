<template>
  <slot :pending="pending" :movie="movie" :uuid="uuid" />
</template>

<script lang="ts" setup>
import type { FetchShowGroupsForMovieQueryVariables, Movie } from '#gql/default'

interface Props {
  fetchKey: string
  fetchParams: FetchShowGroupsForMovieQueryVariables
}

const COMPONENT_NAME = 'ShowGroupsMovieFetch'
const props = defineProps<Props>()

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.fetchKey),
  () => GqlFetchShowGroupsForMovie(props.fetchParams),
  {
    immediate: false,
  }
)

await execute()

const { t } = useI18n()

if (!data.value?.showGroups.data?.[0]?.movie) {
  showError({
    statusCode: 404,
    statusMessage: t('errors.itemNotFound', { item: t('item.page') }),
  })
}

const movie = computed(
  () => (data.value?.showGroups?.data?.[0]?.movie as Movie | null) ?? null
)
const uuid = computed(() => data.value?.showGroups.data[0].uuid ?? null)

defineOptions({
  name: COMPONENT_NAME,
})
</script>

<i18n>
de:
  errors:
    itemNotFound: "{item} nicht gefunden"
  item:
    page: "Seite"
es:
  errors:
    itemNotFound: "{item} no encontrado"
  item:
    page: "Página"
</i18n>
