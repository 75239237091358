<template>
  <NuxtPage v-if="!pending && show" :show />
</template>

<script setup lang="ts">
const route = useRoute()

const { show, pending, error } = await useShow({
  cinemaId: route.query.cinemaId as string,
  urlSlug: route.params.showSlug as string,
})

if (error.value || (!show.value && !pending.value)) {
  const { redirectWithError } = useRedirectError()

  await redirectWithError({
    message: error.value ?? REDIRECT_ERROR_STRINGS.NO_SHOW,
    redirect: {
      name: ROUTE_NAMES.MOVIE_PROGRAM,
      params: {
        movieSlug: route.params.movieSlug as string,
      },
    },
    cinemaId: route.query.cinemaId as string,
  })
}

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]/show/[showSlug]',
    de: '/film/[movieSlug]/vorstellung/[showSlug]',
  },
})

defineOptions({
  name: 'ShowSlug',
})
</script>
