<template>
  <div id="kinoheld-eframe" />
</template>

<script setup lang="ts">
import type { Show } from '#gql/default'

interface Props {
  show: Show
}

const { show } = defineProps<Props>()
const { t } = useI18n()

usePageMetas({
  title: `${show.cinema?.name} | ${t('tickets')}`,
  description: t('description', {
    showName: show.name,
    cinemaName: show.cinema?.name,
  }),
  ...show.meta,
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `kino-${show.cinema?.city.urlSlug}/${show.cinema?.urlSlug}/show/${show.urlSlug}`,
})

defineI18nRoute({
  paths: {
    en: '/movie/[movieSlug]/show/[showSlug]',
    de: '/film/[movieSlug]/vorstellung/[showSlug]',
  },
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShowShowSlugIndex',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei {cinemaName} Tickets für den Film {showName} schnell und bequem online kaufen."
</i18n>
