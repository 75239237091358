<template>
  <PageContainer
    :full-width="false"
    :default-vertical-spacing="false"
    :default-horizontal-spacing="false"
    :height="PAGE_CONTAINER_HEIGHT.MD"
    class="relative"
  >
    <PageContainerBackground
      v-if="movie.heroImage"
      :background-gradient-bottom="true"
      :background-image="movie.heroImage"
      :background-image-position="movie.heroImageAlignment"
      :background-image-modifiers="[]"
      :preload="true"
    />
  </PageContainer>

  <PageContainer
    :full-width="false"
    :default-vertical-spacing="false"
    :default-horizontal-spacing="false"
    class="-mt-[84px]"
  >
    <div
      class="container-spacing-horizontal container relative z-20 grid h-auto grid-cols-[auto,1fr] grid-rows-1 content-end items-end gap-4 md:-mt-44 md:gap-6"
    >
      <ImageTrailer
        :image-config="imageConfig"
        :image-object="thumbnailImage"
        :image-format="GALLERY_IMAGE_FORMAT.PORTRAIT"
        class="w-28 shrink-0 md:row-span-2 md:w-44"
        @clicked="isTrailerModalVisible = !isTrailerModalVisible"
      />

      <div class="space-y-1.5 lg:space-y-2">
        <div v-if="movie.rating?.rating">
          <MovieRating :rating="movie.rating.rating" />
        </div>

        <h1
          class="text-2xl leading-tight sm:text-4xl lg:leading-none"
          :class="{
            'lg:text-6xl': movie.title.length < 40,
            'lg:pr-20 lg:text-5xl': movie.title.length >= 40,
          }"
          v-text="movie.title"
        />

        <MovieFacts :movie />
      </div>

      <div
        class="col-span-2 col-start-1 flex h-[42px] justify-center space-x-4 md:col-span-1 md:col-start-2 md:justify-start"
      >
        <NuxtLink
          :to="
            localeRoute({
              name: ROUTE_NAMES.MOVIE_PROGRAM,
              params: { movieSlug: route.params.movieSlug },
              query: route.query,
            })
          "
          :title="t('btn.showPlaytimes')"
          class="button button-primary button-base w-full md:w-auto"
        >
          <InlineSvg
            :name="SVG_ICONS.MOVIE_ROLL"
            :type="INLINE_SVG_TYPES.ICON"
          />
          <span v-text="t('btn.shows')" />
        </NuxtLink>

        <Button
          :theme="BUTTON_THEME.SECONDARY"
          class="w-full md:w-auto"
          :text="t('btn.movieInfos')"
          :title="t('btn.showMovieInfos')"
          :icon="SVG_ICONS.INFORMATION_OUTLINE"
          @click="isInfoModalVisible = true"
        />
      </div>
    </div>

    <LazyYoutubeModal
      v-if="isTrailerModalVisible"
      :remote-video-id="movie?.trailers?.[0]?.remoteVideoId!"
      @close="isTrailerModalVisible = false"
    />

    <LazyProgramMovieModal
      v-if="isInfoModalVisible"
      :uuid
      @close="isInfoModalVisible = false"
    />
  </PageContainer>
</template>

<script lang="ts" setup>
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
  uuid: string
  thumbnailImage: ImageObject
}

defineProps<Props>()
const { t } = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()

const config = useAppConfig()
const imageConfig = computed(() => config.images.moviePage.poster)

const isTrailerModalVisible = ref(false)
const isInfoModalVisible = ref(false)

defineOptions({
  name: 'MovieHead',
})
</script>

<i18n>
de:
  btn:
    shows: "Vorstellungen"
    movieInfos: "Filminfos"
    showPlaytimes: "Alle Vorstellungen anzeigen"
    showMovieInfos: "Filminfos anzeigen"
</i18n>
