<template>
  <div class="relative">
    <ImageWrapper
      :config="imageConfig"
      :image="imageObject"
      :classes="{
        image: imageClassList,
        container: containerClassList,
      }"
    />
    <Button
      :theme="BUTTON_THEME.TRANSPARENT"
      :title="t('btn.playTrailer')"
      class="absolute inset-0 z-10 h-full w-full"
      @click="emit('clicked')"
    >
      <InlineSvg :name="SVG_ICONS.PLAY_TRAILER" class="h-auto w-full md:mx-4" />
    </Button>
  </div>
</template>

<script setup lang="ts">
interface Props {
  imageFormat: GalleryImageFormat
  imageConfig: ImageConfig
  imageObject: ImageObject
  classes?: {
    container?: string | object | []
    image?: string | object | []
  }
}

const props = defineProps<Props>()
const emit = defineEmits(['clicked'])

const { t } = useI18n()

const { containerClassList, imageClassList } = useGalleryClasses(
  props.imageFormat,
  props.classes
)

defineOptions({
  name: 'ImageTrailer',
})
</script>

<i18n>
de:
  btn:
    playTrailer: "Trailer abspielen"
es:
  btn:
    playTrailer: "Reproducir tráiler"
</i18n>
